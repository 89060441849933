<template>
  <b-modal
    :centered="true"
    :no-stacking="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    v-model="isDisplay"
  >
    <template v-slot:modal-header>
      <h3 class="text-center">Code de confirmation</h3>
    </template>
    <template v-slot:default>
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="httpError"
        @dismissed="httpError = false"
      >
        Une erreur est survenue lors de la vérification du code !
      </b-alert>

      <b-alert
        variant="danger"
        dismissible
        fade
        :show="hasError"
        @dismissed="hasError = false"
      >
        Le code de vérification n'est pas correct !
      </b-alert>

      <form>
        <p>
          Afin de valider votre demande, vous devez encoder le code de
          confirmation qui vous a été envoyé sur votre adresse E-mail
          <span class="font-weight-bold">{{ emailFrom }}</span>
        </p>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="confirmCode">Code *</label>
              <input
                type="text"
                class="form-control"
                id="confirmCode"
                name="confirmCode"
                v-model="confirmCode"
                required
                maxlength="6"
              />
            </div>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary mx-2" @click="cancel">
        Annuler
      </button>
      <button
        type="button"
        class="btn btn-primary mx-2"
        @click="confirm"
        :disabled="!isFormValid"
      >
        Confirmer
      </button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    filesUpload: Array,
    emailFrom: String
  },
  data() {
    return {
      isDisplay: true,
      confirmCode: "",
      httpError: false,
      hasError: false
    };
  },
  computed: {
    isFormValid() {
      return !!this.confirmCode;
    }
  },
  methods: {
    async confirm() {
      this.hasError = false;
      this.httpError = false;
      try {
        const response = await this.$services.transferService.confirm(
          this.confirmCode,
          this.filesUpload.map(x => {
            return { filename: x.fullFilename, size: x.file.size };
          })
        );
        if (response && response.data && response.data.isValid) {
          this.$emit("confirm", response.data);
        } else {
          this.hasError = true;
          this.confirmCode = "";
        }
      } catch {
        this.httpError = true;
        this.confirmCode = "";
      }
    },

    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>
