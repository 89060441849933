import ChunkState from "./ChunkState.enum";

export default class Chunk {
  blockId = "";
  start = 0;
  end = 0;
  state = ChunkState.Unknown;
  retry = 0;

  constructor(index, start, end) {
    this.blockId = this.createBlockId(index);
    this.start = start;
    this.end = end;
    this.state = ChunkState.ToUpload;
    this.retry = 0;
  }

  createBlockId(number) {
    let str = "" + number;
    while (str.length < 6) {
      str = "0" + str;
    }
    return btoa("block-" + str);
  }

  getDelayRetryTime() {
    switch (this.retry) {
      case 0:
        return 0;
      case 1:
        return 5000;
      case 2:
        return 10000;
      case 3:
        return 30000;
      case 4:
        return 60000;
      default:
        return 0;
    }
  }
}
