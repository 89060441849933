import { CHUNK_SIZE, CreateGuid } from "../../../../../constApplication";
import Chunk from "./Chunk";
import ChunkState from "./ChunkState.enum";

export default class FileUpload {
  id = null;
  file = null;
  chunks = [];
  url = "";
  folderName = null;

  constructor(file) {
    this.id = CreateGuid();
    this.file = file;
    this.createChunks(file.size);
    this.url = "";
    if (file && file.webkitRelativePath) {
      const folders = file.webkitRelativePath.split("/");
      if (folders && folders.length > 0) {
        this.folderName = folders[0];
      }
    }
  }

  get fullFilename() {
    if (this.file.webkitRelativePath) {
      return this.file.webkitRelativePath;
    } else {
      return this.file.name;
    }
  }

  get percentageUploaded() {
    const val = this.chunks
      .filter(
        x => x.state === ChunkState.Uploaded || x.state === ChunkState.Error
      )
      .reduce((total, currentChunk) => {
        return total + (currentChunk.end - currentChunk.start);
      }, 0);
    return val > 0 ? Math.round((val / this.file.size) * 100 * 100) / 100 : 0;
  }

  createChunks(fileSize) {
    this.chunks = [];
    if (fileSize < CHUNK_SIZE) {
      this.chunks = [new Chunk(0, 0, fileSize)];
    } else {
      let totalBytes = 0;
      while (totalBytes !== fileSize) {
        const start = totalBytes;
        totalBytes += CHUNK_SIZE;
        if (totalBytes > fileSize) {
          totalBytes = fileSize;
        }
        this.chunks.push(new Chunk(this.chunks.length, start, totalBytes));
      }
    }
  }

  getBlocksList() {
    let blocksList = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    for (let i = 0; i < this.chunks.length; i++) {
      blocksList += "<Latest>" + this.chunks[i].blockId + "</Latest>";
    }
    blocksList += "</BlockList>";
    return blocksList;
  }
}
