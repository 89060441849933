<template>
  <div class="w-100">
    <div class="row" v-if="allFolders && allFolders.length">
      <div class="col-12" v-for="folder in allFolders" :key="folder">
        <b-progress
          class="mb-4 progress-upload bg-warning"
          :max="100"
          :animated="folderPercentageUploaded(folder) < 100"
        >
          <b-progress-bar
            :value="folderPercentageUploaded(folder)"
            variant="success"
          >
            <div class="text" :class="{ uploading: isUploading }">
              <div class="icon-upload mr-2">
                <i class="fas fa-folder-open"></i>
              </div>
              {{ folder }} - {{ folderCount(folder) }}
              fichier(s)
            </div>
            <template v-if="!isUploading">
              <button type="button" class="close" @click="removeFolder(folder)">
                <span>&times;</span>
              </button>
            </template>
          </b-progress-bar>
        </b-progress>
      </div>
    </div>
    <div class="row" v-if="filesUploadNoFolder && filesUploadNoFolder.length">
      <div
        class="col-12"
        v-for="fileUpload in filesUploadNoFolder"
        :key="fileUpload.id"
      >
        <b-progress
          class="mb-4 progress-upload bg-primary"
          :max="100"
          :animated="fileUpload.percentageUploaded < 100"
        >
          <b-progress-bar
            :value="isUploading ? fileUpload.percentageUploaded : null"
            variant="success"
          >
            <div class="text" :class="{ uploading: isUploading }">
              <div class="icon-upload mr-2">
                <i class="fas fa-file"></i>
              </div>
              {{ fileUpload.file.name }}
            </div>
            <template v-if="!isUploading">
              <button
                type="button"
                class="close"
                @click="removeFile(fileUpload.id)"
              >
                <span>&times;</span>
              </button>
            </template>
          </b-progress-bar>
        </b-progress>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.progress-upload {
  border-radius: 10rem;
  height: 24px;
  line-height: 24px;
  position: relative;
  font-size: 85%;
  font-weight: 500;
  color: white;

  div.text {
    text-align: left;
    line-height: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    left: 10px;
    right: 30px;

    &.uploading {
      right: 10px !important;
    }
  }
  button {
    position: absolute;
    right: 10px;

    &.close {
      color: white;
      opacity: 1;
      cursor: pointer;
      outline: none;
    }
  }
  .icon-upload {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    min-width: 20px;
    max-width: 20px;
    width: 20px;
    i {
      color: white;
    }
  }
}
</style>

<script>
export default {
  name: "Files",
  props: {
    filesUpload: Array,
    isUploading: Boolean
  },
  computed: {
    filesUploadNoFolder() {
      let value = [];
      if (this.filesUpload && this.filesUpload.length) {
        value = this.filesUpload.filter(x => !x.folderName);
      }
      return value;
    },
    filesUploadFolder() {
      let value = [];
      if (this.filesUpload && this.filesUpload.length) {
        value = this.filesUpload.filter(x => !!x.folderName);
      }
      return value;
    },
    allFolders() {
      let value = [];
      if (this.filesUploadFolder && this.filesUploadFolder.length) {
        value = [...new Set(this.filesUploadFolder.map(x => x.folderName))];
      }
      return value;
    }
  },
  methods: {
    removeFile(id) {
      this.$emit("removeFile", id);
    },
    removeFolder(name) {
      this.$emit("removeFolder", name);
    },

    folderFiles(name) {
      return this.filesUploadFolder.filter(x => x.folderName.startsWith(name));
    },
    folderCount(name) {
      return this.folderFiles(name).length;
    },
    folderPercentageUploaded(name) {
      const val = this.folderFiles(name).reduce((total, previous) => {
        return total + previous.percentageUploaded;
      }, 0);
      return val > 0
        ? Math.round((val / this.folderCount(name)) * 100) / 100
        : 0;
    }
  }
};
</script>
