<template>
  <b-modal
    title="Problème de fichiers"
    :title-class="'text-danger'"
    :scrollable="true"
    :centered="true"
    :no-stacking="true"
    v-model="isDisplay"
    @hidden="close"
  >
    <template v-slot:default>
      <p class="mb-6">
        Certains de vos fichiers semblent avoir un problème et ne seront pas
        envoyés.
      </p>

      <h6>Fichiers vides</h6>
      <ul class="text-break">
        <li class="mb-2" v-for="item of filesError" :key="item.id">
          {{ item.fullFilename }}
        </li>
      </ul>
    </template>
    <template v-slot:modal-footer>
      <button type="button" class="btn btn-primary mx-2" @click="close">
        Compris
      </button>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      isDisplay: true
    };
  },
  props: {
    filesError: Array
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
