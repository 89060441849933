<template>
  <div>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="hasError"
      @dismissed="hasError = false"
    >
      Une erreur est survenue lors de l'envoi de vos fichiers ! Veuillez
      réessayer !
    </b-alert>

    <div class="card card-custom gutter-b">
      <div class="card-header" v-if="isUploading">
        <div class="card-title">
          <div class="card-label">
            <div class="font-weight-bolder">Envoi en cours</div>
          </div>
        </div>
      </div>

      <!--begin::Body-->
      <perfect-scrollbar
        class="scroll alwaysShow card-body pb-0 text-dark-50 font-size-lg"
        style="max-height: 500px; position: relative;"
      >
        <template v-if="!isUploading">
          <!-- EmailForm -->
          <form
            id="kt_uploader_form"
            novalidate="novalidate"
            @submit.stop.prevent="submit()"
          >
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="emailFrom">Votre mail *</label>
                  <input
                    type="email"
                    class="form-control"
                    id="emailFrom"
                    name="emailFrom"
                    @keydown.enter="submit"
                    v-model="model.from"
                    required
                    maxlength="255"
                    :disabled="isUploading"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="inputTo">Vers *</label>
                  <input
                    type="email"
                    class="form-control"
                    id="inputTo"
                    name="inputTo"
                    ref="inputTo"
                    maxlength="255"
                    autocomplete="off"
                    @keydown.enter="addMail"
                    @keydown.,="
                      addMail();
                      $event.preventDefault();
                    "
                    @keydown.tab="addMail"
                    @blur="addMail"
                    :disabled="isUploading"
                  />
                </div>
              </div>

              <div
                class="col-12"
                v-for="(to, index) in model.tos"
                :key="index"
                tabindex="-1"
              >
                <div class="badge-responsive bg-primary mb-4" tabindex="-1">
                  <div
                    :title="to"
                    :class="{ 'only-text': isUploading }"
                    tabindex="-1"
                  >
                    {{ to }}
                  </div>
                  <button
                    tabindex="-1"
                    type="button"
                    class="close"
                    @click="removeMail(index)"
                    v-if="!isUploading"
                  >
                    <span tabindex="-1">&times;</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="message">Votre message</label>
                  <textarea
                    class="form-control"
                    id="message"
                    name="message"
                    v-model="model.message"
                    maxlength="255"
                    :disabled="isUploading"
                  />
                </div>
              </div>
            </div>
          </form>

          <div
            class="d-flex flex-nowrap align-items-center justify-content-center mb-4"
            :class="{ 'text-danger': !isTotalSizeValid }"
          >
            {{ totalSizeToString }} / 2 Go
            <div
              class="ml-5 cursor-pointer"
              v-if="filesError.length"
              @click="displayFilesError = true"
            >
              <i class="fas fa-exclamation-circle text-danger"></i>
            </div>

            <FilesError
              v-if="displayFilesError"
              :filesError="filesError"
              @close="displayFilesError = false"
            ></FilesError>
          </div>

          <div class="d-flex align-items-center justify-content-center mb-8">
            <button
              type="button"
              class="btn btn-info mx-1"
              @click="$refs.uploader.click()"
            >
              Ajouter des fichiers
            </button>
            <button
              type="button"
              class="btn btn-light mx-1"
              @click="$refs.folderUploader.click()"
            >
              Ajouter un dossier
            </button>
            <input
              hidden
              ref="uploader"
              type="file"
              @change="addFiles"
              multiple
            />
            <input
              ref="folderUploader"
              hidden
              type="file"
              name="file"
              webkitdirectory
              mozdirectory
              directory
              @change="addFolder"
            />
          </div>
        </template>
        <template v-else>
          <div class="d-flex justify-content-center mb-8">
            <vue-ellipse-progress :progress="percentage" class="custom-ellipse">
              <template v-slot:default="{ counterTick }">
                {{ counterTick.currentValue.toFixed(0) }}
              </template>
              <template v-slot:legend-value>
                <div class="ep-legend--value__legendValue" slot="legend-value">
                  &nbsp;%
                </div>
              </template>
            </vue-ellipse-progress>
          </div>
        </template>

        <!-- UploadForm -->
        <div class="d-flex">
          <Files
            :filesUpload="filesUpload"
            :isUploading="isUploading"
            @removeFile="removeFile"
            @removeFolder="removeFolder"
            @percentageDownload="percentage"
          ></Files>
        </div>
      </perfect-scrollbar>

      <div class="card-footer border-top-0 pt-4 text-center">
        <button
          type="button"
          class="btn btn-primary"
          @click="submit"
          :disabled="!isFormValid"
          v-if="!displayConfirmCode && !isUploading"
        >
          Envoyer
        </button>
        <!--end::Body-->
      </div>
    </div>

    <ConfirmCode
      v-if="displayConfirmCode"
      @confirm="upload"
      @cancel="displayConfirmCode = false"
      :filesUpload="filesUpload"
      :emailFrom="model.from"
    ></ConfirmCode>
  </div>
</template>

<script>
import PQueue from "p-queue";
import Files from "./components/Files.vue";
import FilesError from "./components/FilesError.vue";
import ConfirmCode from "./components/ConfirmCode.vue";
import ChunkState from "./types/ChunkState.enum";
import FileUpload from "./types/FileUpload";
import FileError from "./types/FileError";
import { delay, GIGABYTES, MAX_TOS } from "../../../../constApplication";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import emailAddress from "@/assets/plugins/formvalidation/dist/es6/validators/emailAddress";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";

// Thanks to : https://gauravmantri.com/2013/02/16/uploading-large-files-in-windows-azure-blob-storage-using-shared-access-signature-html-and-javascript/

export default {
  name: "Uploader",
  components: {
    Files,
    ConfirmCode,
    FilesError
  },
  data() {
    return {
      queue: null,
      filesUpload: [],
      filesError: [],
      model: {
        from: "",
        message: "",
        tos: []
      },
      displayConfirmCode: false,
      displayFilesError: false,
      isUploading: false,
      hasError: false
    };
  },
  computed: {
    percentage() {
      return this.totalSizeUploaded > 0
        ? this.totalSizeUploaded / this.filesUpload.length
        : 0;
    },
    totalSizeUploaded() {
      let value = 0;
      if (this.filesUpload && this.filesUpload.length) {
        value = this.filesUpload.reduce((total, current) => {
          return total + current.percentageUploaded;
        }, 0);
      }
      return value;
    },
    totalSize() {
      let value = 0;
      if (this.filesUpload && this.filesUpload.length) {
        value = this.filesUpload
          .map(x => x.file.size)
          .reduce((total, current) => {
            return total + current;
          }, 0);
      }
      return value;
    },
    totalSizeToString() {
      return this.totalSize ? (this.totalSize / GIGABYTES).toFixed(2) : "0";
    },
    isTotalSizeValid() {
      return this.totalSize < GIGABYTES * 2;
    },
    isFormValid() {
      return (
        !!this.model &&
        !!this.model.from &&
        !!this.model.tos.length &&
        !!this.filesUpload.length &&
        this.isTotalSizeValid
      );
    }
  },
  mounted() {
    const uploader_form = KTUtil.getById("kt_uploader_form");
    this.fv = formValidation(uploader_form, {
      fields: {
        emailFrom: {
          validators: {
            notEmpty: {
              message: "Ce champ est requis !"
            },
            emailAddress: {
              message: "Veuillez encoder une adresse email valide"
            }
          }
        },
        inputTo: {
          validators: {
            emailAddress: {
              message: "Veuillez encoder une adresse email valide"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  methods: {
    addFolder(event) {
      this.addFiles(event);
    },
    addFiles(event) {
      if (event.target.files && event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i++) {
          if (event.target.files[i].size > 0) {
            this.filesUpload.push(new FileUpload(event.target.files[i]));
          } else {
            this.filesError.push(new FileError(event.target.files[i]));
          }
        }
      }
    },

    removeFile(id) {
      const index = this.filesUpload.findIndex(x => x.id === id);
      if (index !== -1) {
        this.filesUpload.splice(index, 1);
      }
      this.clearFileErrors();
    },
    removeFolder(name) {
      this.filesUpload = this.filesUpload.filter(x => x.folderName !== name);
      this.clearFileErrors(name);
    },
    clearFileErrors(name = null) {
      if (!this.filesUpload.length) {
        this.filesError = [];
      } else {
        if (name) {
          this.filesError = this.filesError.filter(x => x.folderName !== name);
        }
      }
    },

    addMail() {
      const value = this.$refs.inputTo.value;
      if (
        value &&
        value.trim() &&
        this.model.tos.length < MAX_TOS &&
        emailAddress().validate({ value: value }).valid === true
      ) {
        const index = this.model.tos.findIndex(
          x => x.toLocaleLowerCase().trim() === value.toLocaleLowerCase().trim()
        );
        if (index === -1) {
          this.model.tos.push(value.trim());
        }
      }
      this.$nextTick(() => {
        this.clearEmailTo();
      });
    },

    removeMail(index) {
      if (index >= 0 && index < this.model.tos.length) {
        this.model.tos.splice(index, 1);
      }
    },

    async submit() {
      const formValidation = await this.fv.validate();
      if (formValidation === "Valid" && this.isFormValid) {
        this.clearEmailTo();
        this.hasError = false;
        if (!this.displayConfirmCode) {
          try {
            await this.$services.transferService.submit(this.model);
            this.displayConfirmCode = true;
          } catch {
            this.displayConfirmCode = false;
            this.hasError = true;
          }
        }
      }
    },

    async upload(event) {
      this.displayConfirmCode = false;
      this.isUploading = true;
      for (let i = 0; i < this.filesUpload.length; i++) {
        this.filesUpload[i].url = event.urls[i];
        try {
          await this.uploadFile(this.filesUpload[i]);
        } catch (error) {
          this.hasError = true;
          this.resetUploader();
          break;
        }
      }
      if (this.hasError === false) {
        await this.$services.transferService
          .complete(event.transferId)
          .then(() => {
            this.$router.push({ name: "uploadSuccess" });
          })
          .catch(error => {
            this.hasError = true;
            console.error(error);
          })
          .finally(() => {
            this.resetUploader();
          });
      } else {
        this.resetUploader();
      }
    },

    async uploadFile(fileUpload) {
      this.stop();

      this.queue = new PQueue({
        concurrency: 3,
        autoStart: false
      });

      for (let i = 0; i < fileUpload.chunks.length; i++) {
        const chunk = fileUpload.chunks[i];
        const chunkBlob = fileUpload.file.slice(chunk.start, chunk.end);
        const chunkUrl =
          fileUpload.url + "&comp=block&blockid=" + chunk.blockId;

        this.AddQueue(chunkUrl, chunk, chunkBlob);
      }

      return await this.queue
        .start()
        .onIdle()
        .then(
          async () => {
            if (
              fileUpload.chunks.filter(x => x.state === ChunkState.Uploaded)
                .length === fileUpload.chunks.length
            ) {
              const url = fileUpload.url + "&comp=blocklist";

              return await this.$services.blobService
                .completeBlob(
                  url,
                  fileUpload.getBlocksList(),
                  fileUpload.file.type
                )
                .then(
                  () => Promise.resolve("Complete upload blob success"),
                  () => Promise.reject("Complete upload blob error")
                );
            } else {
              return Promise.reject("Upload block error");
            }
          },
          error => Promise.reject(error)
        );
    },

    AddQueue(url, chunk, blob) {
      if (this.queue) {
        this.queue.add(async () => {
          const response = await this.UploadBlob(url, chunk, blob);

          switch (response.state) {
            case ChunkState.Error:
              {
                this.stop();
              }
              break;
            case ChunkState.Uploading:
              {
                if (this.queue && !this.queue.isPaused) {
                  this.AddQueue(url, response, blob);
                } else {
                  this.stop();
                }
              }
              break;
          }
        });
      }
    },

    async UploadBlob(url, chunk, blob) {
      return await delay(chunk.getDelayRetryTime()).then(async () => {
        chunk.state = ChunkState.Uploading;
        return await this.$services.blobService.uploadBlockBlob(url, blob).then(
          () => {
            chunk.state = ChunkState.Uploaded;
            return chunk;
          },
          error => {
            if (this.isNetworkError(error) === true) {
              chunk.state = ChunkState.Error;
            } else {
              chunk.retry += 1;
              if (chunk.retry > 4) {
                chunk.state = ChunkState.Error;
              }
            }
            return chunk;
          }
        );
      });
    },

    stop() {
      if (this.queue) {
        this.queue.pause();
        this.queue.clear();
        this.queue = null;
      }
    },

    resetUploader() {
      this.isUploading = false;
      this.filesUpload = [];
      this.filesError = [];
      this.model.message = "";
      this.model.tos = [];
      this.clearEmailTo();
      this.stop();
    },

    clearEmailTo() {
      this.fv.resetField("inputTo", true);
    },

    isNetworkError(error) {
      var err = JSON.parse(JSON.stringify(error));
      if (!err.message) {
        return false;
      }
      return err.message === "Network Error";
    }
  }
};
</script>
<style lang="scss">
.badge-responsive {
  border-radius: 10rem;
  height: 24px;
  line-height: 24px;
  position: relative;
  font-size: 85%;
  font-weight: 500;
  color: white;

  div {
    text-align: left;
    line-height: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    left: 10px;
    right: 30px;
    top: 0;
    bottom: 0;

    &.only-text {
      right: 10px !important;
    }
  }

  button {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;

    &.close {
      color: white;
      opacity: 1;
      cursor: pointer;
      outline: none;
    }
  }
}

.ep-container.custom-ellipse {
  .ep-legend--container {
    .ep-legend--value {
      display: flex;
      align-items: baseline;

      .ep-legend--value__counter {
        width: 100%;
        max-width: 60px;
        min-width: 60px;
        // h1
        font-size: 2.5rem;
        line-height: 1.2;
        font-weight: 500;
      }
      .ep-legend--value__legendValue {
        // h3
        font-size: 1.5rem;
        line-height: 1.2;
        font-weight: 500;
      }
    }
  }
}
</style>
