import { CreateGuid } from "../../../../../constApplication";

export default class FileError {
  id = null;
  file = null;
  folderName = null;

  constructor(file) {
    this.id = CreateGuid();
    this.file = file;
    if (file && file.webkitRelativePath) {
      const folders = file.webkitRelativePath.split("/");
      if (folders && folders.length > 0) {
        this.folderName = folders[0];
      }
    }
  }

  get fullFilename() {
    if (this.file.webkitRelativePath) {
      return this.file.webkitRelativePath;
    } else {
      return this.file.name;
    }
  }
}
